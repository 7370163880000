.site-header {
	background-color: rgba(255,255,255,1);
	position: relative;
	width: 100%;
	z-index: 100;
	.site-logo {
		margin: 0 0 15px 0;
		text-align: center;
		img {
			margin-top: 15px;
			max-width: 80%;
		}
	}
	.header-menu {
		ul {
			li {
				list-style: none;
				float: left;
				margin-right: 50px;
				text-transform: uppercase;
				font-weight: 700;
				line-height: 30px;
				a {
					font-family: @mainFont;
				}
				.dropdown-menu {
					right: 0 !important;
					left: auto !important;
					background-color: transparent !important;
					border: none !important;
					padding: 0px !important;
					margin-top: 5px !important;
				}
			}
			li.has-child {
				ul.dropdown-child {
					z-index: 100;
					display:none;
					margin-top: 0;
					padding: 15px;
					position: absolute;
					background-color: @gold;
					li {
						float: none;
						margin-right: 0;
						a {
							font-size: 14px;
						}
						a:link, a:active, a:visited {
							color: @white;
						}
						a:hover {
							color: darken(@white, 15%);
						}
					}
					li:last-child {
						position: relative;
						margin-right: auto;
						right: auto;
					}
				}
			}
			li.has-child:hover {
				ul.dropdown-child {
					display: block;
				}
			}
		}
	}
	
	.header-module {
		margin-top: 10px;
		ul {
			float: right;
			li {
				float: right;
				list-style: none;
				a:link, a:active, a:visited {
					color: @white;
					text-decoration: none;
				}
				a:hover {
					color: darken(@white,15%);
					text-decoration: none;
				}
			}
		}
	}
	
	.header-contact {
		background-color: #CFAA41;
		
		padding: 16px 30px;
    	margin-right: -30px;
		ul {
			li {
				list-style: none;
				color: @white;
			}
		}
	}
}
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: auto;
	right: 15px;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 0;
	margin: 2px 0 0;
	list-style: none;
	font-size: 15px;
	text-align: left;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0;
}

.footer-item {
	margin-top: -10px;
	margin-left: 15px;
	margin-right: 15px;
	position: absolute;
	width: 100%;
	padding: 0 15px;
	z-index: 100;
}
.footer-item-head {
	width: 100%;
    margin: 15px 0 0;
    padding: 1px 0;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border-bottom: solid 2px @white;
	h6 {
		color: @white;
		font-size: 36px;
		text-transform: uppercase;
		margin: 5px 0 0px 0px;
		font-family: @mainFont;
		text-align: center;
	}
}
.footer-item-content {
	display: none;
	padding: 15px 0px;
	background-color: @black;
	ul li {
		list-style: none;
		padding: 5px 15px;
		a {
			font-family: @mainFont;
			text-transform: uppercase;
			font-size: 18px;
		}
		a:link {
			color: @gold;
		}
		a:visited {
			color: @gold;
		}
		a:hover {
			color: darken(@gold, 15%);
		}
		ul li {
			a {
				font-size: 14px;
			}
			a:link {
				color: @white;
			}
			a:visited {
				color: @white;
			}
			a:hover {
				color: darken(@white, 15%);
			}
		}
	}
	ul.social-media {
		margin-top: 15px;
	}
}
.footer-selected {
	border-bottom: solid 2px darken(@white, 10%);
	background-color: transparent;
	h6 {
		color: @white;
	}
}
.footer-item-default {
	display: block;
}
.footer-item:last-child {
	margin-bottom: 0px;
	.category-item-head {
		border-bottom: none;
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.header-wrapper {
		margin: 0 30px;
	}
	
	.site-header {
		background-color: rgba(255,255,255,0.7);
		.site-logo {
			text-align: left;
			img {
				margin-top: 10px;
				max-width: 257px;
			}
		}
		.header-menu {
			float: right;
			margin-top: 12px;
			ul {
				li {
					position: relative;
					list-style: none;
					float: left;
					margin-right: 35px;
					text-transform: capitalize;
					font-weight: 700;
					font-size: 14px;
					a {
						font-family: @mainFont;
						white-space: nowrap;
					}
					a:link, a:active, a:visited {
						color: #bbbbbb;
					}
					a:hover {
						color: #bbbbbb;
					}
					i.fa {
						display: none;
						width: 13px;
						position: absolute;
						left: 50%;
						margin-left: -6px;
						color: #bbbbbb;
					}
					.dropdown-menu {
						right: 0 !important;
						left: auto !important;
						background-color: transparent !important;
						border: none !important;
						padding: 0px !important;
						margin-top: 5px !important;
					}
				}
				li.active {
					i.fa {
						display: block;
					}
				}
				li:hover {
					i.fa {
						display: block;
					}
				}
				li:last-child {
					margin-right: 0;
				}
			}
		}
		.header-contact {
			position: absolute;
			right: 0;
			top: 55px;
			padding: 10px 30px;
			ul {
				li {
					list-style: none;
					float: left;
					color: @white;
					margin-right: 15px;
				}
			}
		}
	}
	.site-header-white {
		.header-menu {
			ul {
				li {
					a:link, a:active, a:visited {
						color: #404041;
					}
					a:hover {
						color: #404041;
					}
					i.fa {
						color: #404041;
					}
				}
			}
		}
	}
	.dropdown-menu {
		position: absolute;
		top: 100%;
		left: auto;
		right: 15px;
		z-index: 1000;
		display: none;
		float: left;
		min-width: 160px;
		padding: 0;
		margin: 2px 0 0;
		list-style: none;
		font-size: 15px;
		text-align: left;
		background-color: #fff;
		border: 1px solid #ccc;
		border: 1px solid rgba(0, 0, 0, 0.15);
		border-radius: 0;
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	.site-header {
		.header-menu {
			margin-top: 20px;
		}
		.header-contact {
			top: 66px;
		}
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	.site-header-white {
		background-position: left center;
	}
	
	.site-header {
		.header-menu {
			float: left;
		}
		.header-contact {
			position: relative;
			float: right;
			right: auto;
			top: auto;
			padding: 16px 30px;
			margin-right: -30px;
			ul {
				li {
					float: none;
					margin-right: 0;
				}
			}
		}
		.header-contact::before {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			position: absolute;
			top: 0;
			margin-left: -104px;
			border-width: 0 0 74px 74px;
			border-color: transparent transparent #CFAA41 transparent;
		}
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1440px) {
	.site-header-white {
		background-position: right center;
	}
}

.no-banner {
	.site-header {
		background-color: @white !important;
		-webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.15);
		-moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.15);
		box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.15);
	}
	section.white-background {
		margin-top: 0px !important;
	}
}