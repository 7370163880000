* {
  margin: 0;
  padding: 0;
}

/*Typography Style*/
h1, h2, h3, h4, h5, h6, li, p, a {
	font-family: @mainFont;
}
h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong, li strong, p strong, a strong {
	font-family: @mainFont;
	font-weight: 700;
}

h1 {
	font-size: @font-size-h1 - 10;
	line-height: floor((@font-size-h1 * @line-height-base) - 15);
}
h2 {
	font-size: @font-size-h2 - 10;
	line-height: floor((@font-size-h2 * @line-height-base) - 10);
}
h3 {
	font-size: @font-size-h3 - 8;
	line-height: floor((@font-size-h3 * @line-height-base) - 10);
}
h4 {
	font-size: @font-size-h4 - 5;
	line-height: floor((@font-size-h4 * @line-height-base) - 5);
}
h5 {
	font-size: @font-size-h5 - 2;
	line-height: floor((@font-size-h5 * @line-height-base) - 2);
}
h6 {
	font-size: @font-size-h6;
	line-height: floor((@font-size-h6 * @line-height-base));
}
h1, h2, h3, h4, h5, h6 {
	margin-bottom: 15px;
}
p {
	font-size: @font-size-base - 2;
	line-height: @line-height-computed - 2;
	margin-bottom: 15px;
	text-align: justify;
}
ul,
ol {
	margin-top: 0;
	margin-bottom: 0;
	ul,
	ol {
		margin-bottom: 0;
	}
}
ul li, ol li {
	font-size: @font-size-base - 2;
	line-height: @line-height-computed - 2;
	margin-bottom: 0px;
}

a:link {
	color: @blue;
}
a:active {
	color: @blue;
}
a:visited {
	color: @blue;
}
a:hover {
	color: darken(@blue, 15%);
	text-decoration: none;
}

/*Text Color*/

/*Background Color*/
.background-grey {
	background-color: darken(@white, 20%);
}



/*link*/
a:link, a:active, a:visited {
	color: @gold;
}
a:hover {
	color: darken(@gold, 15%);
}


/*Button*/
.btn {
	background-color: @gold;
	padding: 10px 15px;
	border-radius: 0;
	text-transform: uppercase;
	margin: 15px 0;
	font-size: 13px;
	font-family: @mainFont;
}
a.btn:link {
	color: @white;
}
a.btn:visited {
	color: @white;
}
a.btn:hover {
	color: @white;
	background-color: darken(@red, 20%);
}

.btn-gold {
	background-color: @gold;
}
a.btn-gold:link {
	color: @white;
}
a.btn-gold:visited {
	color: @white;
}
a.btn-gold:hover {
	color: @white;
	background-color: darken(@gold, 20%);
}

.btn-small {
	padding: 5px 8px;
	font-size: 12px;
}

html {
	height: 100%;
}
body {
	height: 100%;
	background-color: @bodyColor;
	color: #bbbbbb;
}
.site-wrapper {
	background-color: @black;
	min-height: 100%;
	width: @fullWidth;
	overflow: hidden;
	position: relative;
}
.site-wrapper-background {
	width: 100%;
	height: 600px;
	position: absolute;
	z-index: -1;
	.gradient;
}
.site-content {
	background-color: #ddd;
	margin-bottom: 30px;
}

section#slide {
	margin-top: -120px;
}

.tp-rightarrow.default {
    background: url(../img/arrow-right.png) no-Repeat 0 0;
    width: 46px;
    height: 53px;
}
.tp-leftarrow.default {
    background: url(../img/arrow-left.png) no-Repeat 0 0;
   	width: 46px;
    height: 53px;
}
.tp-leftarrow:hover, .tp-rightarrow:hover {
    background-position: bottom left;
}
.tp-caption.largeblackbg, .largeblackbg {
    background-color: rgba(0,0,0,0.3);
}
.slide-caption {
	display: none !important;
	white-space: inherit !important;
	h1 {
		text-align: center !important;
		color: @white;
		font-size: 58px !important;
    	line-height: 24px !important;
	}
}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.grid-sizer,
.grid-item {
  width: 100%; 
}

.grid-item {
  float: left;
}

.grid-item img {
  display: block;
  width: 100%;
}

/* 2 columns */
.grid-item--width2 { 
	width: 100%; 
}
.grid-item--width3 { 
	width: 100%; 
}

.owl-carousel .owl-item {
	opacity: 0.2;
  	filter: alpha(opacity=20); /* For IE8 and earlier */
}

.owl-carousel .center {
	opacity: 1 !important;
  	filter: alpha(opacity=100) !important; /* For IE8 and earlier */
}

section.web-content {
	.content {
		.owl-carousel .owl-item {
			opacity: 1;
			filter: alpha(opacity=100); /* For IE8 and earlier */
		}
	}
}

section.header-image {
	width: 100%;
	margin-top: 0px;
	height: 250px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

.header-color {
	height: 60px !important;
	background-color: #3C3C3E;
}

section.white-background {
	background-color: @white !important;
	position: relative;
	margin-top: 0px;
}

.home-shortcut {
	width: 100%;
	a.shortcut {
		width: 100%;
		display: inline-block;
		margin-bottom: 30px;
		background-color: rgba(255,255,255,0.8);
		.icon-left {
			float: none;
			width: 100%;
			text-align: center;
			background-color: #CFAA41;
			img {
				max-width: 20%;
			}
		}
		.content-right {
			padding: 15px;
			float: none;
			width: 100%;
			h5 {
				font-weight: bold;
				color: #3C3C3E;
				margin: 0 0 10px 0;
			}
			p {
				color: #3C3C3E;
				font-size: 13px;
				margin: 0;
			}
		}
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	h1 {
		font-size: @font-size-h1 ;
		line-height: floor((@font-size-h1 * @line-height-base) - 10) ;
	}
	h2 {
		font-size: @font-size-h2 ;
		line-height: floor((@font-size-h2 * @line-height-base)) ;
	}
	h3 {
		font-size: @font-size-h3 ;
		line-height: floor((@font-size-h3 * @line-height-base)) ;
	}
	h4 {
		font-size: @font-size-h4 ;
		line-height: floor((@font-size-h4 * @line-height-base)) ;
	}
	h5 {
		font-size: @font-size-h5 ;
		line-height: floor((@font-size-h5 * @line-height-base)) ;
	}
	h6 {
		font-size: @font-size-h6 ;
		line-height: floor((@font-size-h6 * @line-height-base)) ;
	}
	h1, h2, h3, h4, h5, h6 {
		margin-bottom: 20px ;
	}
	p {
		font-size: @font-size-base ;
		line-height: @line-height-computed ;
		margin-bottom: 20px ;
	}
	ul li, ol li {
		font-size: @font-size-base ;
		line-height: @line-height-computed ;
	}
	
	.btn {
		font-size: 15px;
	}
	
	section#slide {
		margin-top: -55px;
	}
	.slide-caption {
		white-space: inherit !important;
		text-align: left !important;
		display: block;
		h1 {
			font-size: 24px !important;
			line-height: 36px !important;
		}
	}
	.grid-sizer,
	.grid-item {
	  width: 20%; 
	}
	
	/* 2 columns */
	.grid-item--width2 { 
		width: 40%; 
	}
	.grid-item--width3 { 
		width: 60%; 
	}
	
	.tp-bullets {
		top: 107% !important;
	}
	
	section.header-image {
		margin-top: -74px;
		position: fixed;
		height: 350px;
	}
	
	.header-color {
		display: none;
	}
	
	section.white-background {
		margin-top: 275px;
	}
	
	.home-shortcut {
		position: absolute;
		bottom: 15%;
		width: 100%;
		a.shortcut {
			width: 100%;
			margin-bottom: 0;
			.content-right {
				p {
					font-size: 13px;
				}
			}
		}
		
	}

}
/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	section#slide {
		margin-top: -64px;
	}
	
	.tp-caption.largeblackbg, .largeblackbg {
		margin-top: -30px !important;
    	min-height: 10000px !important;
	}
	
	.home-shortcut {
		a.shortcut {
			.icon-left {
				display: none;
				float: none;
				width: 100%;
				text-align: center;
				background-color: #CFAA41;
				img {
					max-width: 20%;
				}
			}
			.content-right {
				float: none;
				width: 100%;
			}
		}
		
	}
	.slide-caption {
		margin-top: -30px !important;
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	section#slide {
		margin-top: -75px;
	}
	
	.tp-caption.largeblackbg, .largeblackbg {
		margin-top: auto;
	}
	
	.home-shortcut {
		a.shortcut {
			.icon-left {
				float: left;
				display: block;
				width: 30%;
				text-align: left;
				img {
					max-width: 100%;
				}
			}
			.content-right {
				float: left;
				width: 70%;
			}
		}
		
	}
	.slide-caption {
		margin-top: auto !important;
	}
	.our-people {
		section.header-image {
			height: 675px;
		}
		section.white-background {
			margin-top: 600px;
		}
	}
}




/*Web Content*/
section.web-content {
	.content {
		overflow: auto;
		margin: 25px 0 100px;
		ul {
			margin-left: 30px;
			margin-bottom: 15px;
		}
		ul.sub-menu {
			margin-left: 0px;
			margin-bottom:  auto;
			li {
				list-style: none;
				border-bottom: solid 1px #BBBDC0;
				padding-bottom: 10px;
				margin-bottom: 10px;
				a:link, a:visited, a:active {
					color: #5D5D60;
				}
				a:hover {
					color: #CFAA41;
				}
			}
			li:last-child {
				border-bottom: none;
			}
			li.active {
				a:link, a:visited, a:active, a:hover {
					color: #CFAA41;
				}
			}
		}
		ul.our-experts {
			margin-left: 0px;
			margin-bottom:  auto;
			li {
				width: 25%;
				list-style: none;
				float: left;
				margin-right: 25px;
				margin-bottom: 30px;
				img {
					margin-bottom: 10px;
				}
				a:link, a:visited, a:active, a:hover {
					color: #5D5D60;
				}
				p {
					text-align: center;
				}
			}
			li:hover {
				opacity: 0.8;
				filter: alpha(opacity=80);
			}
			li:nth-child(3n+1) {
				clear: both;
			}
		}
		img {
			width: 100%;
			margin-bottom: 15px;
		}
		color: #3C3C3E;
		h3, h5 {
			margin-top: 0;
		}
		h3 {
			color: @gold;
			margin-bottom: 5px;
		}
		h5 {
			margin-bottom:  10px;
		}
		.partners-detail {
			img {
				margin: 0;
			}
			a.btn {
				width: 100%;
				margin: 0;
			}
		}
		.news-content {
			.date {
				font-size: 15px;
				color: @gold;
			}
			h2 {
				border-bottom: solid 1px @gold;
				margin-bottom: 30px;
			}
		}
		.contact {
			span.gold {
				color: @gold;
			}
			iframe {
				border: solid 5px @gold;
				margin-bottom: 10px;
				width: 100%;
				height: 300px;
			}
			h5 {
				color: @gold;
			}
			a.btn {
				position: inherit;
				right: 15px;
				top: 35px;
			}
			.btn {
				font-family: 'Montserrat', sans-serif !important;
				color: @white !important;
			}
		}
		.tab-menu-footer {
			display: inline-block;
			margin-bottom: 50px;
			margin-top: 50px;
			ul {
				li {
					font-size: 24px;
					line-height: 36px;
					list-style: none;
					a:link, a:active, a:visited {
						color: #c3c3c3;
					}
					a:hover {
						color: #808080;
					}
				}
				li.active {
					a:link, a:active, a:visited, a:hover {
						color: #808080;
					}
				}
				li:last-child {
					margin-right: 0px;
				}
			}
		}
		.faq {
			.panel {
				background-color: @black;
			}
			.panel-default {
				border-top: none;
				border-left: none;
				border-right: none;
				border-bottom: solid 1px #a5802e;
				border-radius: 0;
				box-shadow: none;
				margin-bottom: 15px;
			}
			.panel-default > .panel-heading {
				color: #808080;
				background-color: transparent;
				border: none;
			}
			
			.panel-group .panel-heading {
				border-bottom: 0;
				margin-bottom: 15px;
			}
			.panel-heading {
				padding: 0;
				border-bottom: 1px solid transparent;
				border-top-right-radius: 0px;
				border-top-left-radius: 0px;
			}
			.panel-title {
				font-size: 18px;
				a:link, a:active, a:visited {
					color: #808080;
					text-decoration: none;
				}
				a:hover {
					color: #a5802e;
					text-decoration: none;
				}
			}
			
			.panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group {
				border-top: none;
			}
			.panel-body {
				padding: 15px 0 0 0;
				a:link, a:active, a:visited {
					color: #808080;
				}
				a:hover {
					color: #a5802e;
				}
			}
		}
		.terms {
			h4, h5 {
				color: #a5802e;
			}
		}
		ul.news-list {
			margin-left:  0;
			li {
				font-size: 18px;
				color: #3C3C3E;
				list-style: none;
				span {
					font-size: 15px;
					color: @gold;
				}
				border-bottom: solid 1px @gold;
				margin-bottom:  20px;
				a:link, a:active, a:visited {
					color: #3C3C3E;
					span {
						color: @gold;
					}
				}
			}
		}
		ul.news-category {
			margin-left: 0;
			li {
				list-style: none;
				a:link, a:active, a:visited {
					color: #3C3C3E;
				}
				margin-bottom: 5px;
			}
			li.title {
				color: @gold;
				letter-spacing: 2px;
				font-size:12px;
			}
		}
		ul.latest-news {
			margin-left: 0;
			li {
				list-style: none;
				a:link, a:active, a:visited {
					color: #3C3C3E;
					span {
						font-size:  13px;
						color: @gold;
					}
				}
				margin-bottom: 10px;
				border-bottom: solid 1px @gold;
			}
			li:first-child {
				margin-bottom: 5px;
				border-bottom: none;
			}
			li.title {
				color: @gold;
				letter-spacing: 2px;
				font-size:12px;
			}
		}
	}
}
ul.about-menu {
	position: relative;
	margin-top: 80px;
	overflow: hidden;
	padding-bottom: 20px;
	li {
		position: relative;
		float: none;
		list-style: none;
		margin-right: 40px;
		margin-bottom: 10px;
		font-size: 24px;
		line-height: 24px;
		a:link, a:active, a:visited {
			color: #c3c3c3;
		}
		a:hover {
			color: @gold;
		}
	}
	li.active {
		a:link, a:active, a:visited {
			color: @gold;
		}
		a:hover {
			color: @gold;
		}
	}
}
hr.gold {
	margin-top: -2px;
	margin-bottom: 21px;
	border: 0;
	border-top: 2px solid @gold;
}

.owl-one .owl-nav {
	position: absolute;
	top: 200px;
	width: 100%;
	margin-top: -40px;
}
.owl-one .owl-dots {
	margin-top: 30px;
}
.owl-carousel .owl-dots .owl-dot.active span, .owl-carousel .owl-dots .owl-dot:hover span {
    background: @gold;
}
.owl-one .owl-nav button.owl-next {
	position: absolute;
	right: 0;
	margin-right: -80px;
	background-image: url(../images/next-btn.png);
	background-position: top left;
	background-repeat: no-repeat;
	width: 52px;
	height: 52px;
	top: -26px;
}
	
.owl-one .owl-nav button.owl-prev {
	position: absolute;
	margin-left: -80px;
	left: 0;
	background-image: url(../images/previous-btn.png);
	background-position: top left;
	background-repeat: no-repeat;
	width: 52px;
	height: 52px;
	top: -26px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
    background-color: transparent;
}
.owl-theme .owl-nav button.owl-next:hover {
    background-image: url(../images/next-btn.png);
    color: #FFF;
    text-decoration: none;
}
.owl-theme .owl-nav button.owl-prev:hover {
   	background-image: url(../images/previous-btn.png);
    color: #FFF;
    text-decoration: none;
}

.owl-board .owl-dots {
	width: 100%;
    position: absolute;
    text-align: center;
    top: 10px;
    margin-top: 130%;
}

.owl-board .owl-nav button.owl-next {
	margin-right: -27px;
}
.owl-board .owl-nav button.owl-prev {
	margin-left: -26px;
}

.mfp-container {
    padding-left: 20px;
    padding-right: 20px;
}

.owl-business h3 {
	margin-top: 80px !important;
}

.owl-business img {
	border: solid 1px #a5802e !important;
}

.owl-business .owl-dots {
	position: absolute;
    top: 10px;
    margin-top: 65%;
    width: 100%;
}

.owl-business .owl-nav {
	top: 0;
    margin-top: 28%;
}
.owl-business .owl-nav button.owl-next {
	margin-right: -27px;
}
.owl-business .owl-nav button.owl-prev {
	margin-left: -26px;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	section.web-content {
		.content {
			margin: 50px 0;
			img {
				margin-bottom: 30px;
			}
			ul {
				margin-left: 30px;
			}
			ul.our-experts {
				margin-left:  0;
				li {
					margin-right: 30px;
				}
			}
			.boards {
				margin-top: 0;
				column-count: 3;
			}
			
			.contact {
				margin-top: 0;
					a.btn {
					position: absolute;
				}
			}
			.tab-menu-footer {
				margin-bottom: 100px;
				margin-top: 0px;
				ul {
					li {
						float: left;
						font-size: 36px;
						margin-right: 69px;
					}
				}
			}
			.faq {
				.panel-title {
					font-size: 21px;
				}
			}
			h2 {
				margin-top: 0;
			}
		}
	}
	ul.about-menu {
		position: relative;
    	margin-top: 80px;
    	overflow: hidden;
    	padding-bottom: 40px;
		li {
			position: relative;
			float: left;
			list-style: none;
			margin-right: 35px;
			margin-bottom: 0;
			font-size: 18px;
			line-height: 24px;
			a:link, a:active, a:visited {
				color: #c3c3c3;
			}
			a:hover {
				color: @gold;
			}
		}
		li:after {
			position: absolute;
			content: "";
			width: 30px;
			height: 17px;
			background-image: url(../images/arrow-about.png);
			background-position: bottom center;
			background-repeat: no-repeat;
			margin-left: -15px;
			left: 50%;
			top: 48px;
			display: none;
		}
		li.active {
			a:link, a:active, a:visited {
				color: @gold;
			}
			a:hover {
				color: @gold;
			}
		}
		li.active:after {
			display: block;
		}
		li:last-child {
			margin-right: 0;
		}
	}
	.mfp-container {
		padding-left: 6px;
		padding-right: 6px;
	}
	.owl-board .owl-dots {
		width: auto;
		position: relative;
		text-align: center;
		top: auto;
		margin-top: 30px;
	}
	.owl-board .owl-nav button.owl-next {
		margin-right: -80px;
	}
	.owl-board .owl-nav button.owl-prev {
		margin-left: -80px;
	}
	.owl-business h3 {
		margin-top: 0px !important;
	}
	.owl-business .owl-dots {
		width: auto;
		position: relative;
		text-align: center;
		top: auto;
		margin-top: 30px;
	}
	.owl-business .owl-nav {
		top: 200px;
		width: 100%;
		margin-top: -40px;
	}
	.owl-business .owl-nav button.owl-next {
		margin-right: -80px;
	}
	.owl-business .owl-nav button.owl-prev {
		margin-left: -80px;
	}
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	ul.about-menu {
		li {
			font-size: 24px;
			margin-right: 40px;
			line-height: 24px;
		}
	}
	section.web-content {
		.content {
			.tab-menu-footer {
				ul {
					li {
						font-size: 48px;
						margin-right: 82px;
					}
				}
			}
		}
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	ul.about-menu {
		li {
			margin-right: 106px;
		}
		li:last-child {
			margin-left: 2px;
		}
	}
	section.web-content {
		.content {
			.tab-menu-footer {
				ul {
					li {
						font-size: 48px;
						margin-right: 182px;
					}
				}
			}
		}
	}
}
