footer {
	width: 100%;
	padding: 20px 0;
	z-index: 0;
	p {
		font-size: 12px;
		line-height: 30px;
		margin: 0;
		a {
			margin-right: 13px;
		}
		a.first {
			margin-left: 0px;
		}
		a:last-child {
			margin-right: 0;
		}
	}
}
footer.footer-home {
	color: @white;
	p {
		text-align: center;
		color: @white;
		a:link, a:active, a:visited {
			color: @white;
		}
		a:hover {
			color: @white;
			text-decoration: underline;
		}
	}
}
footer.footer-page {
	color: #bbbbbb;
	p {
		text-align: center;
		color: #bbbbbb;
		a:link, a:active, a:visited {
			color: #bbbbbb;
		}
		a:hover {
			color: #bbbbbb;
			text-decoration: underline;
		}
	}
}
.clear-footer {
	height: 0px;
}




/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.clear-footer {
		height: 61px;
	}
	footer {
		position: absolute;
		bottom: 0;
		p {
			line-height: 21px;
			a {
				margin-right: 30px;
			}
			a.first {
				margin-left: 40px;
			}
		}
	}
	footer.footer-home {
		p {
			text-align: left;
		}
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	footer {
	}
	.clear-footer {
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
}