.header-banner {
	width: 100%;
	height: 150px;
	position: relative;
	margin-top: 0;
	z-index: 0;
	background-size: cover;
	.banner-overlay {
		width: 100%;
		height: 100%;
		background: -moz-linear-gradient(top, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
		.title-overlay {
			width: 100%;
			position: absolute;
			bottom: 0;
			background-color: rgba(0,0,0,0.5);
			h1 {
				font-size: 26px;
				line-height: 28px;
				color: @white;
				font-weight: 300;
				text-transform: uppercase;
			}
		}
	}
}


/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.header-banner {
		height: 250px;
		margin-top: 0;
		.banner-overlay {
			h1 {
				font-size: 48px;
				line-height: 52px;
			}
		}
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	.header-banner {
		height: 300px;
		.banner-overlay {
			h1 {
			}
		}
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	.header-banner {
		height: 350px;
		.banner-overlay {
			h1 {
			}
		}
	}
}

@media (min-width: 1600px) {
	
}


