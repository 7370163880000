.site-nav-mobile {
	position: relative;
	float: right;
	margin-top: 0px;
	z-index: 110;
	.btn {
		margin-top: 0;
		margin-bottom: 0;
		padding: 7px 10px;
		font-size: 15px;
	}
	a.btn {
		font-family: @mainFont;
		color: @white;
	}
}


/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	
	.site-nav {
		margin-left: 0;
		float: left;
		ul {
			li {
				float: left;
				list-style: none;
				a {
					display: block;
					font-size: 12px;
					text-transform: uppercase;
					font-family: @mainFont;
					padding: 10px 7px;
					
				}
				a:link, a:active, a:visited, a:hover {
					color: @white;
					text-decoration: none;
				}
				ul.site-nav-child {
					display: none;
				}
			}
			li:hover {
				a {
					background-color: darken(@blue, 10%);
				}
			}
			li.active {
				a {
					background-color: darken(@blue, 10%);
				}
			}
			li.has-child:hover {
				ul.site-nav-child {
					margin-top: 0px;
					background-color: @blue;
					display: block;
					position: absolute;
					li {
						float: none;
						border-bottom: solid 1px darken(@blue, 10%);
						ul.site-nav-child {
							display: none;
						}
						a {
							background-color: @blue;
							padding: 10px 15px;
						}
						a:after {
							display: none;
						}
					}
					li:hover {
						a {
							background-color: darken(@blue, 10%);
						}
					}
					li:last-child {
						border-bottom: none;
					}
					li.has-child {
						a:after {
							content: "";
							display: block;
							background: url("../images/caret_right.png") no-repeat !important;
							width: 4px;
							height: 6px;
							float: right;
							margin: 8px 10px 0px -6px;
						}
					}
					li.has-child:hover {
						ul.site-nav-child {
							margin-top: -33px;
							background-color: darken(@white,5%);
							display: block;
							position: absolute;
							left: 100%;
							li {
								border-left: solid 1px darken(@white, 15%);
								border-bottom: solid 1px darken(@white, 15%);
								width: 100%;
								a {
									text-transform: none;
									white-space: nowrap;
									width: auto;
									height: auto;
								}
								a:after {
									display: none;
								}
							}
							li:hover {
								background-color: darken(@white,10%);
							}
						}
					}
				}
			}
		}
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	.site-nav {
		ul {
			li {
				a {
					font-size: 13px;
					padding: 10px 12px;
				}
			}
		}
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	.site-nav {
		ul {
			li {
				a {
					font-size: 14px;
					padding: 10px 20px;
				}
			}
		}
	}
}


